<template>
  <div class="m-settings m-general-setting">
    <h1 class="title is-1">{{ $t('settings.heading') }}</h1>
    <p>{{ $t('settings.noneAvailable') }}</p>
  </div>
</template>
<script>
export default {
  name: 'GeneralSettings'
};
</script>
